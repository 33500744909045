import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import React, { useState, useEffect } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import {
  userHeaders,
  clientsColumns,
  usersColumns,
  analysisCard,
} from "../../Helpers/DataHelper";
import {
  getAnalyticCount,
  getOrderHistory,
  getRevisionHistory,
  getTopUsers,
  getTopClients,
  getAllAppraiser,
  getAllInspector,
  getAllClient,
} from "../../Actions/dashboard";
import Icon from "../../Helpers/Icon";
import { deleteAccount, suspendAccount } from "../../Actions/account";
import AlertBox from "../../Components/Alert";
import BarGraph from "../../Components/BarChart";
import SelectInput from "../../Components/Core/Select";
import Grid from "../../Components/DataGrid";
import AnalyticsCard from "../../Components/AnalyticsCard";
import { useDispatch, useSelector } from "react-redux";
import PopNotificaton from "../../Components/PopNotification";
import { Navigate, useNavigate } from "react-router";
import Login from "../Login";

const Dashboard = () => {
  const [range, setRange] = useState("All");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [revisionHistory, setRevisionHistory] = useState([]);
  const [topInspector, setTopInspector] = useState([]);
  const [topAppraiser, setTopAppraiser] = useState([]);
  const [topClient, setTopClient] = useState([]);
  const [months, setMonths] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [analyticsData, setAnalyticsData] = useState([]);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [show, setShow] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const auth = useSelector((state) => state.auth);
  const navigation = useNavigate();

  function handleCardClick(idx) {
    setSelectedCard(idx);
  }

  function handleClose() {
    setSelectedCard(null);
  }

  useEffect(() => {
    if (!auth.authenticate) {
      navigation("/login")
      return;
    }

    // Generate month names for x-axis
    const monthNames = Array.from({ length: 12 }, (_, index) => {
      const date = new Date(2000, index); // Use arbitrary year and day
      return date.toLocaleString("default", { month: "short" });
    });
    setMonths(monthNames);
    getAnalyticsCount(range);
  }, []);

  const getAnalyticsCount = async (range) => {
    setIsLoading(true);
    setRange(range);
    setData([]);
    setAnalyticsData([]);

    const params = {
      type: range,
    };

    try {
      const result = await dispatch(getAnalyticCount(params));

      // console.log("result",result)
      setIsLoading(false);
      if (result && result.data && result.data) {
        const {
          orders = 0,
          appraiser = 0,
          inspector = 0,
          reviewer = 0,
          client = 0,
        } = result.data?.counts;
        const updatedAnalysisCardData = [
          {
            ...analysisCard["admin"].totalOrders,
            value: orders,
          },
          {
            ...analysisCard["admin"].totalRevisions,
            value: reviewer,
          },
          {
            ...analysisCard["admin"].totalAppraisers,
            value: appraiser,
          },
          {
            ...analysisCard["admin"].totalInspectors,
            value: inspector,
          },
          {
            ...analysisCard["admin"].totalClients,
            value: client,
          },
        ];
        setAnalyticsData(updatedAnalysisCardData);
        if (result && result.data?.ordersByMonth) {
          const allMonthsData = Array.from({ length: 12 }, (_, index) => {
            const monthData = result.data?.ordersByMonth.find(
              (item) => item.month === index + 1
            );
            return monthData ? monthData.count : 0;
          });
          setOrderHistory(allMonthsData);
        }

        if (result && result.data?.revisionDataByMonth) {
          const allMonthsData = Array.from({ length: 12 }, (_, index) => {
            const monthData = result.data?.revisionDataByMonth.find(
              (item) => item.month === index + 1
            );
            return monthData ? monthData.count : 0;
          });
          setRevisionHistory(allMonthsData);
        }
        setTopInspector(result.data?.topUsers?.inspector);
        setTopClient(result.data?.topUsers?.clients);
        setTopAppraiser(result.data?.topUsers?.appraiser);
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);

      setIsLoading(false);
      setAlert({
        show: true,
        type: "error",
        message: error?.response?.data?.error || "An error occurred.",
      });
    }
  };

  const deleteUser = async (id) => {
    setIsLoading(true);
    try {
      const params = {
        type: "User",
      };

      dispatch(deleteAccount(params, id))
        .then((result) => {
          setAlert({
            show: true,
            type: "success",
            message: "Account deleted successfully",
          });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "error",
            message: err?.response.data?.error,
          });
        });
    } catch (validationError) {
      setIsLoading(false);
    }
  };

  const suspendUser = async (id) => {
    setIsLoading(true);
    try {
      const params = {
        type: "User",
      };

      dispatch(suspendAccount(params, id))
        .then((result) => {
          setAlert({
            show: true,
            type: "success",
            message: "Account suspended successfully",
          });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "error",
            message: err?.response.data?.error,
          });
        });
    } catch (validationError) {
      setIsLoading(false);
    }
  };

  return (
    <Template
      showHeader
      showSidebar
      headerProps={{
        showSearch: true,
        showNotification: true,
        showProfile: true,
        showsideMenu: true,
      }}
      sidebarProps={{
        page: "Dashboard",
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
      }}
    >
      <AlertBox
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onChange={(value) => {
          setAlert({ show: value, type: "", message: "" });
        }}
      />

      <Box
        sx={{
          border: "1px solid #FFFFFF",
          background:
            "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
          boxShadow: "15px 18px 50px #00000029",
          borderRadius: "24px",
          backgroundColor: "#F2F2F2",
          opacity: "1",
          padding: "1.5em",
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          margin: "auto",
          width: isSmallScreen ? "96%" : "78%",
          marginLeft: isSmallScreen ? "0" : "17.5em",
          marginTop: "4em",
          marginBottom: '2em'
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            cursor: "pointer",
            margin: "0px 4px",
            paddingTop: "2px",
            position: "relative",
            marginBottom: "22px",
          }}
        >
          <span
            style={{
              color: "#000000",
              font: "normal normal 600 22px/37px Poppins",
            }}
          >
            Analytics Overview
          </span>

          <SelectInput
            style={{ width: "202px", marginLeft: "1em", padding: "1.2px 0" }}
            isTransparent
            options={[
              { value: "last week", label: "Last Week" },
              { value: "last 30 days", label: "Last Month" },
              { value: "last year", label: "Last Year" },
              { value: "this year to date", label: "Year to Date" },
              { value: "All", label: "All" },
            ]}
            value={range}
            onChange={(option) => getAnalyticsCount(option)}
          />
        </Box>
        <Box
          sx={{
            marginTop: "40px",
            height: '100vh',
            overflow: 'scroll',
            marginBottom: '2em'
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "initial",
              alignItems: "center",
              cursor: "pointer",
              margin: "0px 4px",
              paddingTop: "2px",
              position: "relative",
              marginBottom: "22px",
            }}
          >
            {analyticsData.map((item, index) => (
              <AnalyticsCard
                key={index}
                number={item.value}
                icon={item.icon}
                text={item.label}
                isShow={selectedCard == index ? item.label : null}
                onClick={() => handleCardClick(index)}
                onClose={handleClose}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "initial",
              alignItems: "center",
              cursor: "pointer",
              margin: "0px 4px",
              paddingTop: "2px",
              position: "relative",
              marginBottom: "22px",
            }}
          >
            <BarGraph data={orderHistory} labels={months} title="Order" />
            <BarGraph data={revisionHistory} labels={months} title="Revision" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mb: "1em",
            }}
          >
            <span
              style={{
                color: "black",
                font: "normal normal 600 19px/31px Poppins",
                fontSize: "19px",
                marginLeft: "10px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            >
              Top Appraisers
            </span>
            <Grid
              isLoading={isLoading}
              data={topAppraiser}
              columns={usersColumns}
              deleteAccount={(userId) => deleteUser(userId)}
              suspendAccount={(userId) => suspendUser(userId)}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mb: "1em",
            }}
          >
            <span
              style={{
                color: "black",
                font: "normal normal 600 19px/31px Poppins",
                fontSize: "19px",
                marginLeft: "10px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            >
              Top Inspectors
            </span>
            <Grid
              isLoading={isLoading}
              data={topInspector.map((user) => ({
                ...user,
                licenseNumber: user?.stateLicenses?.length && user?.stateLicenses[0]?.licenseNumber,
              }))}
              columns={usersColumns}
              deleteAccount={(userId) => deleteUser(userId)}
              suspendAccount={(userId) => suspendUser(userId)}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mb: "1em",
            }}
          >
            <span
              style={{
                color: "black",
                font: "normal normal 600 19px/31px Poppins",
                fontSize: "19px",
                marginLeft: "10px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            >
              Top Clients
            </span>
            <Grid
              isLoading={isLoading}
              data={topClient.map((data) => ({
                ...data,
                totalProperties: data?.PropertyId?.length
                  ? data?.PropertyId?.length
                  : 0,
              }))}
              columns={clientsColumns}
              deleteAccount={(userId) => deleteUser(userId)}
              suspendAccount={(userId) => suspendUser(userId)}
            />
          </Box>
        </Box>
      </Box>
    </Template>
  );
};

export default Dashboard;
